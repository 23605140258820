$(document).on('turbolinks:load', function () {
    if (document.querySelector('#new_contact')) {
        let requiredInputNames = ['contact[email]', 'contact[contact_name]', 'contact[phone]',
            'contact[description]']

        const phoneInput = document.getElementById("contact_phone");

        phoneInput.addEventListener("input", function (event) {
            let value = phoneInput.value.replace(/\D/g, ""); // Remove non-numeric characters

            if (value.startsWith("38")) {
                value = value.substring(2); // Remove "38" if manually entered
            }

            let formattedValue = "+38 ";

            if (value.length > 0) formattedValue += "(" + value.substring(0, 3);
            if (value.length >= 3) formattedValue += ") " + value.substring(3, 6);
            if (value.length >= 6) formattedValue += value.substring(6, 10);

            phoneInput.value = formattedValue;
        });


        document.querySelectorAll('input, textarea').forEach(fe => {
            fe.addEventListener('input', () => {
                fe.classList.remove('error')
            })

            fe.addEventListener('focus', () => {
                fe.classList.remove('error')
            })
        })

        document.querySelector('#new_contact').addEventListener('submit', function (e) {
            document.querySelector('input[type="submit"]').disabled = false
            let valid = true

            let inputs = document.querySelectorAll('input, textarea')
            inputs.forEach(input => {
                if (requiredInputNames.includes(input.name)) {
                    if (!checkRequired(input.value)) {
                        valid = false
                        input.classList.add('error')
                    }
                }

                if (input.value !== '') {
                    switch (input.name) {
                        case 'contact[email]':
                            if (!validateEmail(input.value)) {
                                valid = false
                                input.classList.add('error')
                            }
                            break;
                        case 'contact[phone]':
                            if (input.value.length !== 17) {
                                valid = false
                                input.classList.add('error')
                            }
                            break;
                    }
                }
            })

            if (!valid) {
                e.preventDefault()
            }
            return valid
        })
    }
})

function checkRequired(value) {
    if (value) {
        value = value.toString().trim()
        return value !== "";
    } else {
        return false
    }
}

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function addErrorMessage(input, message) {
    let errorField = input.parentElement.querySelector('.form-error')
    if (errorField) {
        if (errorField.innerText === "") {
            errorField.innerText = message
        } else {
            errorField.innerText += ` ${message}`
        }
        errorField.classList.remove('d-none')
        input.addEventListener('change', function () {
            errorField.classList.add('d-none')
            document.querySelector('.submit-button').disabled = false
        })
    }
}